<div id="globalBanner"></div>
<ecl-app-harmonised *ngIf="language$ | async as language">
  <ecl-site-header-harmonised [siteName]="'DISCLAMER_1' | translate" group="1">
    <ecl-site-header-harmonised-action>
      <ecl-site-header-harmonised-language></ecl-site-header-harmonised-language>
    </ecl-site-header-harmonised-action>
  </ecl-site-header-harmonised>
  <ecl-page-header>
    <breadcrumb #parent> 
      <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
          </li>
          <li *ngIf="route.terminal" class="breadcrumb-item active">{{ route.displayName }}</li>
        </ng-template>
      </ol>
    </breadcrumb>
  </ecl-page-header>

  <div class="ecl-u-bg-white ecl-u-pb-xl">
    <router-outlet></router-outlet>
  </div>
  <ecl-footer-harmonised group="1">
    <div eclFooterHarmonisedRow>
      <div eclFooterHarmonisedColumn>
        <div eclFooterHarmonisedSection>
          <h2 eclFooterHarmonisedTitle [innerHTML]="'DISCLAMER_1' | translate">
          </h2>
          <div eclFooterHarmonisedDescription>
            {{ 'DISCLAMER_2' | translate }}
          </div>
        </div>
      </div>
      <div eclFooterHarmonisedColumn>
        <div eclFooterHarmonisedSection>
          <h2 eclFooterHarmonisedTitle [isSeparator]="true">
            {{ 'ecl.footer.CONTACT-US' | translate }}
          </h2>
          <ul eclFooterHarmonisedList>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                [attr.aria-label]="'ecl.footer.CONTACT-INFO-DG' | translate"
                variant="standalone"
                [href]="'https://ec.europa.eu/growth/contact_' + language"
              >
                {{ 'ecl.footer.CONTACT-INFO-DG' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div eclFooterHarmonisedSection>
          <h2 eclFooterHarmonisedTitle [isSeparator]="true">
            {{ 'ecl.footer.FOLLOW-US' | translate }}
          </h2>
          <ul eclFooterHarmonisedList [isInline]="true">
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                aria-label="Facebook"
                variant="standalone"
                href="https://www.facebook.com/EU.Growth"
              >
                <ecl-icon
                  iconSet="social-media"
                  icon="facebook-negative"
                  size="xs"
                >
                </ecl-icon>
                <span eclLinkLabel>Facebook</span>
              </a>
            </li>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                aria-label="Twitter"
                variant="standalone"
                href="https://www.twitter.com/EU_Growth"
              >
                <ecl-icon
                  iconSet="social-media"
                  icon="twitter-negative"
                  size="xs"
                >
                </ecl-icon>
                <span eclLinkLabel>Twitter</span>
              </a>
            </li>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                aria-label="Instagram"
                variant="standalone"
                href="https://www.instagram.com/eu_growth/"
              >
                <ecl-icon
                  iconSet="social-media"
                  icon="instagram-negative"
                  size="xs"
                >
                </ecl-icon>
                <span eclLinkLabel>Instagram</span>
              </a>
            </li>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                aria-label="Youtube"
                variant="standalone"
                href="https://www.youtube.com/euenterprise"
              >
                <ecl-icon
                  iconSet="social-media"
                  icon="youtube-negative"
                  size="xs"
                >
                </ecl-icon>
                <span eclLinkLabel>Youtube</span>
              </a>
            </li>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                aria-label="RSS"
                variant="standalone"
                href="https://ec.europa.eu/growth/rss"
              >
                <ecl-icon icon="rss" size="xs"> </ecl-icon>
                <span eclLinkLabel>RSS</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div eclFooterHarmonisedColumn>
        <div eclFooterHarmonisedSection>
          <h2 eclFooterHarmonisedTitle [isSeparator]="true">
            {{ 'ecl.footer.ABOUT-US' | translate }}
          </h2>
          <ul eclFooterHarmonisedList>
            <li eclFooterHarmonisedListItem>
              <a
                eclLink
                eclFooterHarmonisedLink
                [attr.aria-label]="'ecl.footer.INFO-ABOUT-DG' | translate"
                variant="standalone"
                [href]="'https://ec.europa.eu/growth/about-us_' + language"
              >
                {{ 'ecl.footer.INFO-ABOUT-DG' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div eclFooterHarmonisedSection>
          <h2 eclFooterHarmonisedTitle [isSeparator]="true">
            {{ 'ecl.footer.RELATED-SITES' | translate }}
          </h2>
          <ul eclSiteFooterList>
            <li eclSiteFooterListItem>
                <a eclLink
                    eclSiteFooterLink
                    aria-label="Business, Economy, Euro"
                    variant="standalone"
                    href="https://commission.europa.eu/business-economy-euro_en" target="_blank">
                    {{ 'DISCLAMER_3' | translate }}
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ecl-footer-harmonised>
</ecl-app-harmonised>
<div id="cck"></div>
