import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Amplify } from 'aws-amplify';
import { awsconfig } from '../aws-exports';
import { AppRoutingModule } from './app-routing.module';
import { AppStarterService } from './app-starter.service';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CustomErrorHandler } from './core/services/custom-error-handler';
import { BackService } from './shared/components/back/back.service';

Amplify.configure(awsconfig);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
  ],
  providers: [
    AppStarterService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appStarterService: AppStarterService) => {
        return () =>
          new Promise<void>(resolve => {
            appStarterService.start().subscribe(() => resolve());
          });
      },
      deps: [AppStarterService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (backService: BackService) => {
        return () =>
          new Promise<void>(resolve => {
            backService.init();
            resolve();
          });
      },
      deps: [BackService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
