import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, EuiAppConfig, LocalStorageService } from '@eui/core';
import { Observable } from 'rxjs';
import { LanguageService } from './core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class AppStarterService {
  constructor(
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    @Inject(CONFIG_TOKEN) private config: EuiAppConfig
  ) {}

  start(): Observable<any> {
    return this.languageService.setLanguage(
      this.localStorageService.get('preferedLocale') ||
        this.config.global.i18n.i18nService.defaultLanguage
    );
  }
}
