import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from './core/services/language.service';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  language$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private breadcrumbService: BreadcrumbService,
    private titleService: Title,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.language$ = this.languageService.getLanguage();
    this.breadcrumbService.breadcrumbChanged.subscribe( crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
    this.loadScript();
    this.loadGlobalBanner();
    this.loadCCK();
    this.loadAnalytics();
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'SME-WEEK';
    const titles = routesCollection.filter((route) => route.displayName);
    if (!titles.length) { return title; }
    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} | ${title}`;
  }

  private titlesToString(titles) {
      return titles.reduce((prev, curr) => {
          return `${curr.displayName} - ${prev}`;
      }, '');
  }

  private loadScript() {
    return new Promise((resolve, reject) => {
        const element = this.document.createElement('script');
        element.type = 'text/javascript';
        element.src = 'https://europa.eu/webtools/load.js';
        element.onload = resolve;
        element.onerror = reject;
        this.elementRef.nativeElement.appendChild(element);
    })
  }

  // global banner
  private loadGlobalBanner() {
    window.addEventListener("wtReady", () => {
      // here you can start using any $wt API methods.
      $wt.render("globalBanner", { 
        service: "globan",
        lang: "en",
        theme: "dark",
        logo: true,
        link: true,
        mode: false,
        zindex : 40
      });
    });
  }

  // cookie consent kit
  private loadCCK() {
    window.addEventListener("wtReady", () => {
      // here you can start using any $wt API methods.
      $wt.render("cck", { 
        utility: "cck",
        url: {
          en: "https://my.ec.europa.eu/cookie-policy_en"
        }
      });
    });
  }
  // webtool analytics
  private loadAnalytics() {
    window.addEventListener("wtReady", () => {
      // here you can start using any $wt API methods.
      $wt.render("analytics", { 
        'utility' : 'analytics',
        'siteID' : 'c3cc11a5-5221-43a2-9c88-6d71bfcd79b3',
        'instance' : 'ec',
        'mode' : 'manual',
        'sitePath' : ['sme-week.ec.europa.eu'],
      });
    });
  }
}
