import { ModulesConfig } from '@eui/core';
import { environment } from '../environments/environment';
const WEBTOOLS_CONFIG = {
  webtools: environment.webtools,
};

export const MODULES: ModulesConfig = {
  core: {
    base: '/api',
  },
  webtools: WEBTOOLS_CONFIG.webtools
};
