import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: 'home',
  //   data: { breadcrumb: 'Home' },
  //   loadChildren: () =>
  //     import('./features/home/home.module').then(m => m.HomeModule),
  // },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/event/event.module').then(m => m.EventModule),
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
