import { environment } from './environments/environment';

const APP_CONFIG = {
  apiUrl: environment.apiUrl,
};

export const awsconfig = {
  API: {
    endpoints: [
      {
        name: 'Main',
        endpoint: APP_CONFIG.apiUrl,
      },
    ],
  },
};
