import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BackService {
  stack: string[] = [];

  constructor(private router: Router) {}

  init() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.stack.push(event.url);
      });
  }

  goBack() {
    if (this.stack.length > 0) {
      this.stack.pop();
      this.router.navigateByUrl(this.stack.pop());
    }
  }
}
