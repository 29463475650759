import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from './core/services/language.service';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs';
import { Title } from '@angular/platform-browser';
import { WebtoolsService } from './shared/services/webtools.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  language$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private breadcrumbService: BreadcrumbService,
    private titleService: Title,
    private router: Router,
    private webtoolsService: WebtoolsService,
  ) {}

  ngOnInit(): void {
    this.language$ = this.languageService.getLanguage();
    this.breadcrumbService.breadcrumbChanged.subscribe( crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
    this.loadGlobalBanner();
    this.loadCCK();
    this.initializeWebTools();
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'SME-WEEK';
    const titles = routesCollection.filter((route) => route.displayName);
    if (!titles.length) { return title; }
    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} | ${title}`;
  }

  private titlesToString(titles) {
      return titles.reduce((prev, curr) => {
          return `${curr.displayName} - ${prev}`;
      }, '');
  }

  // global banner
  private loadGlobalBanner() {
    window.addEventListener("wtReady", () => {
      // here you can start using any $wt API methods.
      $wt.render("globalBanner", { 
        service: "globan",
        lang: "en",
        theme: "dark",
        logo: true,
        link: true,
        mode: false,
        zindex : 40
      });
    });
  }

  // cookie consent kit
  private loadCCK() {
    window.addEventListener("wtReady", () => {
      // here you can start using any $wt API methods.
      $wt.render("cck", { 
        utility: "cck",
        url: {
          en: "https://my.ec.europa.eu/cookie-policy_en"
        }
      });
    });
  }

  // webtool analytics
  private initializeWebTools() {
    this.webtoolsService.initialize().subscribe({
        complete: () => {
            this.webtoolsService.trackPageView();

            // The delay is to avoid the first page from being tracked twice in case the navigation ends after the method is executed
            setTimeout(() => {
                this.startTrackingPageViewAnalytics();
            }, 1000);
        },
        error: error => {
            console.error('Error initializing the analytics service', error);
        },
    });
  }

  private startTrackingPageViewAnalytics() {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
          this.webtoolsService.trackPageView();
      });
  }
  
}
