import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EuiAllModule } from '@eui/components';
import { EuiEditorModule } from '@eui/components/externals/eui-editor';
import { UxAllModule } from '@eui/components/legacy';
import { EclAllModule } from '@eui/ecl-core';
import { TranslateModule } from '@ngx-translate/core';
import { BackComponent } from './components/back/back.component';
import { CardComponent } from './components/card/card.component';
import { FaqLinkComponent } from './components/faq-link/faq-link.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { DatetimeComponent } from './components/form/datetime/datetime.component';
import { EditorComponent } from './components/form/editor/editor.component';
import { FileComponent } from './components/form/file/file.component';
import { GroupComponent } from './components/form/group/group.component';
import { InputComponent } from './components/form/input/input.component';
import { MultiselectComponent } from './components/form/multiselect/multiselect.component';
import { RadioComponent } from './components/form/radio/radio.component';
import { SelectComponent } from './components/form/select/select.component';
import { FormMessageComponent } from './components/form/shared/form-message/form-message.component';
import { FormTooltipComponent } from './components/form/shared/form-tooltip/form-tooltip.component';
import { TextareaComponent } from './components/form/textarea/textarea.component';
import { LabelValueComponent } from './components/label-value/label-value.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { CountryNamePipe } from './pipes/country-name.pipe';

@NgModule({
  imports: [
    RouterModule,
    EclAllModule,
    TranslateModule,
    UxAllModule,
    EuiAllModule,
    EuiEditorModule,
    BreadcrumbModule
    
  ],
  declarations: [
    CardComponent,
    InputComponent,
    MultiselectComponent,
    TextareaComponent,
    FormMessageComponent,
    FormTooltipComponent,
    CheckboxComponent,
    EditorComponent,
    SelectComponent,
    GroupComponent,
    DatetimeComponent,
    RadioComponent,
    FileComponent,
    BackComponent,
    FaqLinkComponent,
    LabelValueComponent,
    CountryNamePipe
  ],
  exports: [
    RouterModule,
    EclAllModule,
    TranslateModule,
    UxAllModule,
    EuiAllModule,
    BreadcrumbModule,

    CardComponent,
    InputComponent,
    MultiselectComponent,
    TextareaComponent,
    FormMessageComponent,
    FormTooltipComponent,
    CheckboxComponent,
    EditorComponent,
    SelectComponent,
    GroupComponent,
    DatetimeComponent,
    RadioComponent,
    FileComponent,
    BackComponent,
    FaqLinkComponent,
    LabelValueComponent,
    CountryNamePipe
  ],
})
export class SharedModule {}
