export const environment = {
  production: true,
  apiUrl: 'https://api.test.sme-week.ec.europa.eu',
  webtools: {
    'initializationScriptUrl': 'https://webtools.europa.eu/load.js',
    'analyticsConfig': {
      'utility': 'analytics',
      'siteID': '53901542-e3cb-442e-9b67-09b038cc882f',
      'instance': 'ec',
      'mode': 'manual',
      'sitePath': ['test.sme-week.ec.europa.eu'],
    }
  }
};
