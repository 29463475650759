import { GlobalConfig } from '@eui/core';

export const GLOBAL: GlobalConfig = {
  appTitle: 'CSDR-app',
  i18n: {
    i18nService: {
      defaultLanguage: 'en',
      languages: [
        'en',
        'bg',
        'cs',
        'da',
        'de',
        'et',
        'el',
        'es',
        'fr',
        'it',
        'lv',
        'lt',
        'hu',
        'mt',
        'nl',
        'pl',
        'pt',
        'ro',
        'sk',
        'sl',
        'fi',
        'sv',
        'hr',
        'tr',
      ],
    },
    i18nLoader: {
      i18nFolders: ['i18n-eui', 'i18n-ecl', 'i18n'],
    },
  },
};
