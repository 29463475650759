import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EuiAppConfig } from '@eui/base';
import { CONFIG_TOKEN } from '@eui/core';
import { Webtools } from '../models/webtools.model';
import { EMPTY, Observable, iif, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';

declare const $wt: Webtools;

@Injectable({
    providedIn: 'root',
})
export class WebtoolsService {
    constructor(
        @Inject(CONFIG_TOKEN) private readonly config: EuiAppConfig,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {}

    initialize(): Observable<void> {
        this.addAnalyticsConfigurationToHtml();
        this.runInitializationScript();
        return this.notifyWhenInitialized();
    }

    /**
     * Track the page view in the Europa Analytics tool.
     *
     * To validate the page view in real time on SME-WEEK non-production environments, the Tracker debugger functionality can
     * be used on the Analytics testing environment:
     * https://webanalytics.acc.fpfis.tech.ec.europa.eu/analytics/#/c3cc11a5-5221-43a2-9c88-6d71bfcd79b3/settings/debugger/
     *
     * The uBlock Edge browser extension must be disabled on EC computers for the tracking to work.
     * See https://ec.europa.eu/newsroom/cem/newsletter-archives/52330
     */
    trackPageView() {
        if (!$wt) {
            // This should not be common, but it can occur if the loading of the script load.js finishes after the
            // Angular application has already initialized.
            console.error('Page view track ignored.');
            return;
        }
        $wt.trackPageView();
    }

    /**
     * Add the Europa Analytics configuration to the HTML.
     *
     * Service documentation:
     *    Functional - https://webgate.ec.europa.eu/fpfis/wikis/display/EuropaAnalyticsWiki/Europa+Analytics+Documentation
     *    Technical  - https://webgate.ec.europa.eu/fpfis/wikis/display/webtools/Europa+Analytics
     */
    private addAnalyticsConfigurationToHtml() {
        const configHtmlElement = document.createElement('script');
        configHtmlElement.type = 'application/json';
        configHtmlElement.innerHTML = JSON.stringify(this.config.modules.webtools.analyticsConfig);

        this.addToHeadHtmlElement(configHtmlElement);
    }

    private runInitializationScript() {
        const loadJsHtmlElement = document.createElement('script');
        loadJsHtmlElement.src = this.config.modules.webtools.initializationScriptUrl;
        loadJsHtmlElement.type = 'text/javascript';

        this.addToHeadHtmlElement(loadJsHtmlElement);
    }

    private addToHeadHtmlElement(node: Node) {
        const headHtmlElement = this.document.getElementsByTagName('head').item(0);

        headHtmlElement.insertBefore(node, headHtmlElement.firstChild);
    }

    /**
     * Checks that the initialization script finished initializing and is ready to accept calls
     * by verifying that its parameters were loaded.
     * It retries 5 times after a delay of 500ms before throwing an error.
     */
    private notifyWhenInitialized(): Observable<void> {
        return iif(
            () => typeof $wt !== 'undefined' && !!$wt.analytics?.parameters,
            EMPTY,
            throwError(
                () =>
                    new Error(
                        'The Webtools configuration was not initialized. The variable $wt.analytics.parameters is empty.',
                    ),
            ),
        ).pipe(
            retry({
                count: 5,
                delay: 500,
            }),
        );
    }
}
